<template>
    <div>
        <div class="alert alert-danger">
            <p></p>
        </div>
        <form autocomplete="on" @submit.prevent="login" method="post">
            <div class="nav-title">{{$t('login')}}</div>
          <div class="small-spacer"></div>
            <div class="input-group">
                <label for="email" class="label">{{$t('email')}}</label>
                <input type="email" name="email" autocomplete="on" id="email" class="input" v-model="user.email" required>
            </div>
            <div class="input-group">
                <label for="password" class="label">{{$t('password')}}</label>
                <input type="password" name="password" autocomplete="on"  id="password" class="input"   v-model="user.password" required>
            </div>
            <button type="submit" class="btn primary">{{$t('sign in')}}</button>

       <div class="signup-hint">


         <!--  Noch kein Account? Hier <router-link class="signup-hint-link" to="/website/register">registrieren Sie sich kostenlos</router-link> für eine 30-Tage Testversion. -->
        </div>
        </form>
    </div>
</template>

<script>


    export default {
        data(){
            return {
              user: {
                email: '',
                user: '',
                password: ''
              }
            }
        },
        methods: {
            login(){
              axios.post('users/login', this.user)
                  .then((response) => {

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    axios.defaults.headers.common = {'Authorization': `Bearer ${response.data.token}`, 'UID': response.data.user.id }

                    return this.$emit('login', response.data);
                  }).catch((response) => {
                this.$emit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
              });
              /*
              var app = this;

                this.$auth.login({
                    params: {
                        email: app.email,
                        password: app.password,
                        remember: true,
                    },
                    success:() => {
                        this.$gtag.event('login', { method: 'Login Form' });
                            app.$emit('login');

                    },
                    error: (response) =>  {
                        this.$gtag.event('login.error', { method: 'Login Form' })
                        this.$emit('toast', { text: this.$t('login failed'), type: 'notification'});
                    },
                    rememberMe: true,
                    redirect: '/dashboard',
                    fetchUser: true,
                });*/
            },
        }
    }
</script>
