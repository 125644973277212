<template>

    <div class="employee-selector">
        <div class="input-group">
            <div class="label-small">{{$t(title)}}</div>
            <div class="input-queue">
                <div class="employeelist" v-bind:class="{ disabled: disabled, singleselect : !multiple }" v-for="(item, index) in employee_list">
                    <employee-avatar :user="item"  :large="false" :xlarge="false" :vertical="false"></employee-avatar>
                    <div class="remove-action" v-if="multiple" @click="unset(item.id)">&times;</div>
                </div>
                <input v-bind:disabled="disabled"  autocomplete="random" @click="active=true" v-on:keydown="active=true" v-on:keydown.esc="active=false;filter=''" v-on:keydown.delete="deletelast" v-on:keyup.enter="selectfirst" type="text"
                       :placeholder="inputplaceholder" class="input autocomplete" @focus="active=true" @blur="delayedblur"
                       v-model="filter">
            </div>
            <div class="dropdown-anchor" v-show="active" >
                <div class="dropdown-list" v-bind:class="{disabled: disabled, 'is-filtered' : filter.length > 0}">
                    <div @click="additem(item.id);active=false;filter=''" class="dropdown-item" :key="index"
                         v-for="(item, index) in filtered_list">
                        <employee-avatar :user="item"  :large="false"  :xlarge="false" :vertical="false"></employee-avatar>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>


<script>

    import EmployeeAvatar from './EmployeeAvatar'

    export default {
        props: {
            title: {
                type: String,
                required: false,
                default: 'employee'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: Array,
                required: false,
                default: function () {
                    return [];
                }
            },
            multiple : {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                values: this.value,
                employees: [],
                filter: '',
                active: false

            }
        },
        mounted(){
                this.load();


        },
        watch: {
            values: function () {

               this.$emit('input', this.values);

            },
          value : function(){
              this.values = this.value;
          }
        },
        methods: {
            load: function () {

                this.values = this.value;
                axios.get('employees').then((response) => {
                    this.loaded = true;
                    this.employees = response.data;
                    this.values = this.value;
                });
            },
            selectfirst: function () {
                if (this.filtered_list.length > 0) {
                    this.additem(this.filtered_list[0].id);
                } else {
                    this.filter = '';
                }
            },
            deletelast: function () {
                if (this.filter.length == 0) {
                    this.values.pop();
                }
            },
            additem: function (id) {
                if (this.multiple) {
                    this.values.push(id);
                } else {
                    this.values = [id];
                }
                this.filter = '';
            },
            unset: function (id) {
                if (this.multiple) {
                    this.values.splice(this.values.indexOf(id), 1);
                } else {
                    this.values = [];
                }
            },
            delayedblur: function () {
                setTimeout(() => {
                    this.active = false;
                    this.filter = '';
                }, 200);

            },
            employee_by_id: function (id) {
                for (var i in this.employees) {
                    if (this.employees[i].id == id) {
                        return this.employees[i];

                    }

                }
            }
        },
        computed: {
            inputplaceholder: function () {
                if (this.disabled) {
                    return '';
                }
                if (this.multiple) {
                    return this.$t('add');
                } else {
                    return this.$t('select');
                }
            },
            employee_list: function () {
                var employee_data = [];

                for (var i in this.values) {
                    employee_data.push(this.employee_by_id(this.values[i]));
                }
                return employee_data;
            },
            filtered_list: function () {

                var filteredlist = Array.from(this.employees);

                var dynlist = [];
                var add = false;
                for (var i in filteredlist) {
                    add = false;
                    for (var data in filteredlist[i]) {
                        if (typeof filteredlist[i][data] == 'string') {
                            if (filteredlist[i][data].toLowerCase().includes(this.filter.toLowerCase())) {
                                add = true;
                            }
                        }
                    }
                    if (add && !this.values.includes(filteredlist[i]['id'])) {
                        dynlist.push(filteredlist[i]);
                    }


                }

                return dynlist;
            }
        },
        components: {

            EmployeeAvatar
        }
    }

</script>
