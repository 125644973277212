<template>
  <div class="help-article">

    <router-link :to="$route.path + '?help=index'" @click="this.state.help.topic = null">{{ $t('back') }}</router-link>

    <Loader v-if="loading == true"></Loader>

    <template v-if="article != null">
      <div class="article-title">
        {{ article.title }}
      </div>
      <div class="article-metadata">{{ $date(article.updated_at).format('DD.MM.YYYY') }} ({{ article.language }})</div>
      <div class="article-content" v-html="article.content">
      </div>
    </template>

    <div class="article-not-found" v-if="loading == false && article == null">
      <svgimg name="warning"></svgimg> {{ $t('help article not found') }}
    </div>

  </div>
</template>
<script>

import HelpRequired from './HelpRequired';
import svgimg from "../svgimg";
import Loader from '../Loader'
export default {
  components: {
    HelpRequired,
    svgimg,
    Loader
  },
  props: ['state', 'article','loading']
}
</script>
