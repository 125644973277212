<template>
  <div class="row help-topics">


    <div class="col">
      <div>
        <div class="help-title">Mitarbeiter</div>
        <div >
          <div class="help-link" @click="help('employee.create')">Mitarbeiter erstellen</div>
          <div class="help-link" @click="help('employee.targethours')">Sollzeiten einrichten</div>
          <div class="help-link" @click="help('employee.salaryrun')">Lohnlauf</div>
        </div>
      </div>
    </div>
    <div class="col">
      <div>
        <div class="help-title">Objekte</div>
        <div >
          <div class="help-link" @click="help('employee.create')">Messungen</div>
          <div class="help-link" @click="help('timetracking.tracker.qr')">Tracker</div>
          <div class="help-link" @click="help('employee.salaryrun')">Lohnlauf</div>
        </div>
      </div>
    </div>
    <div class="col">
      <div>
        <div class="help-title">Dokumente</div>
        <div >
          <div class="help-link" @click="help('documents.templates.create')">Vorlagen erstellen</div>
          <div class="help-link" @click="help('documents.billing.qr')">QR-Einzahlungsscheine</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {}
  },
  methods: {
    help: function (topic) {
      this.$parent.$emit('help', topic);
    }
  }
}

</script>
