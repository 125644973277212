<template>
        <div :title="user.first_name+ ' ' + user.last_name" v-if="typeof user !== 'undefined' && user !== null" class="user-avatar-container" v-bind:class="{vertical : vertical, large: large}">
            <img  class="user-avatar medium" v-if="user.avatar != null" v-bind:class="{vertical : vertical, xlarge: xlarge}" :src=" envir + 'license/' + user.license_id + '/User/' + user.id + '/xs_' + user.avatar.filename">
            <svgimg name="avatar_placeholder" v-else class="user-avatar medium placeholder" v-bind:class="{vertical : vertical, xlarge: xlarge}"></svgimg>
            <div class="user-avatar-name" v-if="!imageonly"><template v-if="user.employee_number != null && user.employee_number != ''">({{$t('employee_number_short')}}: {{user.employee_number}}) </template>       {{user.first_name+ " " + user.last_name}}
                <div class="meta">{{user.email}}</div>
            </div>


        </div>
</template>

<script>

    import svgimg from "@/components/svgimg";

    export default {
      mounted: function () {
      },
      components: {
        svgimg
      },
      computed: {
        envir: function () {
          return process.env.VUE_APP_CDNROOT;
        }
      },
      props: ['user','vertical','large','xlarge','imageonly']
    }
</script>
