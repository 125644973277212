<template>
    <img @click="handleClick" class="svgimg" v-bind:class="{ 'prevent_dark_invert': prevent_invert }" :src="'/assets/svg/' + name + '.svg'">
</template>
<script>
    export default {
        props: ['name','prevent_invert'],
        methods: {
            handleClick() {
                this.$emit('click')
            }
        }
    }
</script>
