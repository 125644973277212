<template>
    <div class="toast-container">
        <div class="toast" :key="'toast_' + item.timestamp" v-for="(item, index) in toasts"></div>
    </div>
</template>
<script>


    export default {
        data() {
            toasts : []
        }
    }
</script>

