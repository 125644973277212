<template>
    <div>
        <div class="alert alert-danger" v-if="error && !success">
            <p>{{$t('error occured during registration')}}</p>
        </div>
        <div class="alert alert-success" v-if="success">
            <p>{{$t('registration completed')}} <a href="/">{{$t('sign in lowercase')}}</a>.<br><br></p>
        </div>
        <form autocomplete="off" @submit.prevent="register" v-if="!success" method="post">
            <div class="nav-title">{{$t('register')}}</div>
            <br>

            <h1>30 Tage kostenlos testen</h1>
            <p>Testen Sie CleanPro App um alles kennenzulernen. Unsere Instruktionsvideos zeigen genaueres über die
            Möglichkeiten. Der Firmenname wird gleichzeitig auch der Lizenzname. Diese können Sie später jederzeit anpassen.<br><br>
            </p>

            <h2>Lizenz</h2>
            <div class="input-group" v-bind:class="{ 'has-error': error && errors.company }">
                <label for="name" class="label">{{$t('license title')}}</label>
                <input type="text" id="name" class="input" v-model="company" required>
                <span class="help-block" v-if="error && errors.company">{{ errors.company }}</span>
            </div>

            <h2>Personalien</h2>


            <div class="input-group" v-bind:class="{ 'has-error': error && errors.first_name }">
                <label for="first_name" class="label">{{$t('first_name')}}</label>
                <input type="first_name" id="first_name" class="input" placeholder="" v-model="first_name" required>
            </div>


            <div class="input-group" v-bind:class="{ 'has-error': error && errors.last_name }">
                <label for="last_name" class="label">{{$t('last_name')}}</label>
                <input type="last_name" id="last_name" class="input" placeholder="" v-model="last_name" required>
            </div>

            <CountrySelector title="country" v-model="country"></CountrySelector>

            <div class="input-group" v-bind:class="{ 'has-error': error && errors.email }">
                <label for="email" class="label">{{$t('email')}}</label>
                <input type="email" id="email" class="input" placeholder="user@example.com" v-model="email" required>
                <span class="help-block" v-if="error && errors.email">{{ errors.email }}</span>
            </div>

            <div class="input-group" v-bind:class="{ 'has-error': error && errors.password }">
                <label for="password" class="label">{{$t('password')}}</label>
                <input type="password" id="password" class="input" v-model="password" required>
                <span class="help-block">Das Kennwort muss mindestens 8 Zeichen lang sein. </span>
                <span class="help-block" v-if="error && errors.password">{{ errors.password }}</span>
            </div>

            <!--
            <div class="input-group" v-bind:class="{ 'has-error': error && errors.email }">
                <label for="email" class="label">{{$t('subscription')}}</label>
                <select v-model="subscription_type">
                    <option value="yearly">{{$t('yearly subscription')}} CHF 2199.00 / {{$t('year')}}</option>
                    <option value="yearly">{{$t('monthly subscription')}}  CHF 199.00 / {{$t('month')}}</option>
                </select>
            </div>
-->
<br>
            <label><input type="checkbox">Ich habe die <a href="/agb" target="_blank">AGB</a> gelesen und akzeptiert.</label><br><br>
            <button type="submit" class="btn primary">{{$t('register')}}</button>


        </form>
    </div>
</template>

<script>

    import CountrySelector from  "./CountrySelector";
    export default {
        data(){
            return {
                country: 'CH',
                company: '',
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                subscription_type: 'yearly',
                error: false,
                errors: {},
                success: false
            };
        },
        methods: {
            register(){
                var app = this;
                this.$auth.register({
                    data: {
                        company: app.company,
                        email: app.email,
                        password: app.password,
                        country: app.country,
                        first_name: app.first_name,
                        last_name: app.last_name,
                        subscription_type: app.subscription_type,
                    },
                    success: function () {
                        app.success = true
                    },
                    error: function (resp) {
                        app.error = true;
                        app.errors = resp.response.data.errors;
                    },
                    redirect: null
                });
            }
        },
        components: {
            CountrySelector
        }
    }
</script>
