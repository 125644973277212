<template>
  <div class="app-container">

     <router-view :state="state" @toast="toast" @logout="logout" @login="login"
                 v-if="($route.path != '/' && !state.loggedin && state.token == null) || $route.meta.auth == false && $route.matched[0].path == '/process'"></router-view>

    <div class="fullwidth" v-if="!state.loggedin && state.token == null && $route.path == '/' && $route.meta.auth == false">

      <div class="landing-container">

        <a class="landing-section landing-darkblue" href="/">
          <div class="landing-row">
            <div class="landing-logo">
              <Svgimg :prevent_invert="true" name="cleanpro-logo-h-trp-rgb"></Svgimg>
            </div>
          </div>
        </a>
        <div class="landing-section bgimage">
          <img src="/images/landing/bottom-separator-db.svg" class="blue-top">
        </div>

        <router-view @toast="toast" @logout="logout" @login="login"></router-view>

       <!--  <div class="landing-section blue footer">
          <div class="landing-row">
            <strong>CleanPro App</strong><br>
            <a class="footer_link" href="/imprint">Impressum</a><br>
            <router-link to="/agb" class="footer_link" href="/agb" target="_blank">Allgemeine Geschäftsbedingungen
            </router-link>
            <br>
            <a class="footer_link" href="/roadmap">Roadmap</a><br>
            <a class="footer_link" href="mailto:support@cleanpro.app">Support</a>

          </div>
        </div>
-->
        <Cookies></Cookies>

      </div>


    </div>


    <div class="toast-container">
      <div class="toast" v-for="(item, index) in toasts" v-bind:class="item.type">{{ item.text }}</div>
    </div>


    <transition name="fade" mode="out-in">

      <div v-if="state.loggedin && $route.path != '/' && $route.meta.auth == true" class="spa-container">
        <div class="navbar">
          <router-link to="dashboard" class="brand">
            <div class="app-logo">
              <svgimg name="logo-wide-app" :prevent_invert="true"></svgimg>
            </div>
            <div class="app-badge">{{ $t('unlimited') }}</div>
          </router-link>
          <div class="main-navigation">
            <router-link v-if="state.permissions.includes(item.permission)" :title="$t(item.title)"
                         :to="item.url" class="nav-item" :key="index"
                         v-for="(item, index) in state.navigation">
              <div class="nav-icon">
                <Svgimg :name="item.icon" :prevent_invert="true"></Svgimg>
              </div>
              <div class="nav-label">{{ $t(item.title) }}</div>
            </router-link>
          </div>
        </div>
        <div class="workarea">
          <div class="actionbar">


            <div class="breadcrumb">
              <template v-if="typeof state.license != 'undefined'">

                {{ state.license.title }}
              </template>

              <template v-for="(item, index) in $router.currentRoute.matched"> &gt; {{ $t(item.name) }}
              </template>

            </div>


            <div class="searchbar" v-if="state.permissions.includes('perm.search')">
              <input type="text" v-bind:class="{ filled : state.globalsearch.length > 0 }"
                     v-model="state.globalsearch" value="" :id="uuid" :name="uuid" autocomplete="false" spellcheck="false"
                     class="search-input">
            </div>

            <router-link v-if="state.permissions.includes('perm.timetracking.index')" to="/timerecord" class="notifier">
              <Svgimg name="stopwatch"></Svgimg>
            </router-link>

            <router-link v-if="state.permissions.includes('perm.timetracking.index')" to="/qrtracking" class="notifier">
              <Svgimg name="qr_code"></Svgimg>
            </router-link>

            <a @click.prevent="state.help.topic='index'; $router.push($route.path + '?help=index'); " class="notifier">
              <Svgimg name="help"></Svgimg>
            </a>

            <router-link to="/notifications" class="notifier">
              <Svgimg :name="((notification_meta > 0)?'notification-active':'notification')"></Svgimg>
              <div v-if="notification_meta > 0" class="notification-count">{{ notification_meta }}
              </div>
            </router-link>


            <div @click="state.show_profile_dropdown=!state.show_profile_dropdown" class="userinfo">
              <div class="credentials">
                <div class="fullname">{{ state.user.first_name + ' ' + state.user.last_name }}</div>
                <div class="licensename">{{ state.license.title }}</div>
              </div>
              <div class="avatar">
                <EmployeeAvatar :imageonly="true" :user="state.user"></EmployeeAvatar>
              </div>
              <div class="usermenu">
                <Svgimg name="chevron-down"></Svgimg>
                <ProfileDropdown @logout="logout" v-if="state.show_profile_dropdown" :state="state"></ProfileDropdown>
              </div>

            </div>

          </div>
          <div class="contentarea">

            <router-view @pasteboard="pasteboard" @update_alerts="update_alerts" v-if="state.globalsearch == ''"
                         @toast="toast" :state="state"></router-view>
            <GlobalSearch :searchquery="state.globalsearch" :state="state" v-else></GlobalSearch>


          </div>

        </div>

      </div>
    </transition>

    <Wizard :state="state" v-if="state.loggedin && state.token != null && state.wizard.enabled"></Wizard>
    <HelpSystem  v-if="state.loggedin && state.token != null && state.help.topic != null" @help="help($event)" @helpclose="state.help.topic = null" :state="state"></HelpSystem>
  </div>

</template>

<script>


import Svgimg from "@/components/svgimg";
import EmployeeAvatar from "@/components/EmployeeAvatar";
import Toasts from "@/components/helpers/Toasts";
import GlobalSearch from "@/components/helpers/GlobalSearch";
import Landing from "@/components/landing/Landing";
import ProfileDropdown from "@/components/helpers/ProfileDropdown";
import Cookies from "@/components/cookies/Cookies";
import Wizard from "./components/licensewizard/Wizard";
import LogRocket from 'logrocket';
import HelpSystem from "@/components/helpsystem/HelpSystem";

export default {
  components: {
    Svgimg,
    EmployeeAvatar,
    Toasts,
    GlobalSearch,
    Landing,
    ProfileDropdown,
    Cookies,
    Wizard,
    HelpSystem
  },
  name: 'App',
  data() {
    return {

      autologin_complete: false,
      checklogin_interval: null,
      state: {
        pasteboard: {
          timetracking: {},
          employee: {},
          document: {}
        },
        wizard: {
          enabled: false,
          step: 0,
          minimized: false
        },
        loggedin: false,
        loaded: false,
        license: {},
        token: null,
        user: {},
        permissions: [],
        navigation: [
          {title: 'dashboard', icon: 'dashboard', url: '/dashboard', permission: 'perm.dashboard'},
          {title: 'customers', icon: 'customers', url: '/customers', permission: 'perm.customers.index'},
          {title: 'contacts', icon: 'contacts', url: '/contacts', permission: 'perm.contacts.index'},
          {title: 'objects', icon: 'objects', url: '/objects', permission: 'perm.objects.index'},
          {title: 'projects', icon: 'projects', url: '/projects', permission: 'perm.projects.index'},
          {title: 'contracts', icon: 'contracts', url: '/contracts', permission: 'perm.contracts.index'},
          {title: 'tasks', icon: 'tasks', url: '/tasks', permission: 'perm.tasks.index'},
          //   {title: 'documents', icon: 'documents', url: '/documents', permission: 'perm.documents.index'},
          {title: 'employees', icon: 'employeesfilled', url: '/users', permission: 'perm.users.index'},
           // {title: 'resources', icon: 'gantt', url: '/resources', permission: 'perm.resources.index'},
          {title: 'controlling', icon: 'controlling', url: '/controlling', permission: 'perm.controlling.index'},
          {title: 'settings', icon: 'settings', url: '/settings', permission: 'perm.settings.index'}
        ],
        help: {
          topic: "index"
        },
        globalsearch: '',
        notifications: [],
        show_profile_dropdown: false,
      },
      toasts: [],

    }
  },
  computed: {
    notification_meta: function () {
      var count = 0;
      for (var i = 0; i < this.state.notifications.length; i++) {
        if (this.state.notifications[i].read_at == null) {
          count++;
        }
      }
      return Math.min(count, 9);
    },
    uuid() {
      return 'xxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  },
  mounted() {
    this.state.token = localStorage.getItem('token');
    this.state.user = JSON.parse(localStorage.getItem('user'));

    if (typeof this.state.token != 'undefined' && typeof this.state.user != 'undefined' && this.state.user != null && typeof this.state.user.id != 'undefined') {
      axios.defaults.headers = {'Authorization': `Bearer ${this.state.token}`, UID: this.state.user.id};
    }
    this.rememberLogin();
    this.check_login();

    this.checklogin_interval = setInterval(() => {
      this.check_login();
    }, 120000);

    setInterval(() => {
      this.expire_toasts();
    }, 1000)

    this.state.help.topic = 'index';
  },
  created() {



  },
  methods: {
    help: function(value){
      this.state.help.topic = value;
        this.$router.push({ path: this.$route.path, query: { help: value }});
    },
    pasteboard: function (pastevalue) {

      this.state.pasteboard[pastevalue.pasteboard] = pastevalue.data;

    },
    rememberLogin: function () {
      if (this.state.token != null && this.state.user != null) {
        axios.post('users/autologin', this.state)
            .then((response) => {

              this.init_app();
              this.state.user = response.data.user;
              this.state.token = response.data.token;
              this.state.loggedin = true;
              axios.defaults.headers = {'Authorization': `Bearer ${this.state.token}`, UID: this.state.user.id}
              this.loggedin = true;
              this.autologin_complete = true;
              LogRocket.identify(this.state.user.id, {
                name: this.state.user.full_name,
                email: this.state.user.email,
              });

              if (this.$route.path == '/') {
                this.$router.push('/dashboard');
              }

            }).catch((response) => {
          this.state.user = null;
          this.state.token = null;
        });
      }
    },
    logout: function () {
      axios.get('users/logout')
          .then((response) => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            axios.defaults.headers.common = {};
            this.state.token = null;
            this.state.user = {};
            this.state.license = {};
            this.state.loggedin = false;

            this.$router.push('/');
          }).catch((response) => {

        this.state.user = {};
        this.state.license = {};
        this.state.token = null;
        this.$router.push('/');

      });
    },
    logouteverywhere: function () {
      axios.post('users/logouteverywhere', this.state)
          .then((response) => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.state = {
              token: null,
              user: null
            };

            this.loggedin = false;
            this.$router.push('/');
          });
    },
    login: function (data) {
      this.state.user = data.user;
      this.state.token = data.token;
      this.state.loggedin = true;
      this.init_app();

      LogRocket.identify(this.state.user.id, {
        name: this.state.user.full_name,
        email: this.state.user.email,
      });
      this.$router.push('/dashboard');
    },
    check_login: function () {

      if (this.state.token != null) {


        var token = localStorage.getItem('token');
        var user = JSON.parse(localStorage.getItem('user'));
        if (user == null) {
          var email = '';
        } else {
          var email = user['email'];
        }
        axios.post('users/checklogin',
            {
              token: token,
              email: email
            }).then((response) => {
          axios.defaults.headers = {'Authorization': `Bearer ${this.state.token}`, UID: this.state.user.id}
          this.state.loggedin = true;
          this.load_license();

        }).catch((response) => {
              this.logout();
            }
        );
      }
    },
    update_alerts: function () {
      this.state.notifications = [];
      this.load_alerts();
    },
    expire_toasts: function () {
      this.toasts.forEach((item, index) => {
        if (this.toasts[index].timestamp < new Date(Date.now() - 5000)) {
          this.toasts.splice(index, 1);
        }
      });
    },
    toast: function (content) {

      if (typeof content.type == 'undefined') {
        content.type = 'notification';
      }
      if (this.toasts.length > 4) {
        this.toasts.shift();
      }
      this.toasts.push({text: content.text, type: content.type, timestamp: Date.now()});
    }
    ,
    check_permission: function (permission) {
      if (typeof this.state.permissions != 'undefined') {
        return this.state.permissions.includes(permission);
      } else {
        return false;
      }

    },
    logincheck: function () {

    },
    init_app: function () {
      this.load_permissions();
      this.load_license();
    },
    load_license: function () {

      if (typeof this.state.token != 'undefined' && this.state.token != null && this.state.loggedin == true) {
        axios.get('license/' + this.state.user.license_id,
        ).then((response) => {
          this.state.license = response.data;
          this.load_alerts();
          var wizard_state = JSON.parse(localStorage.getItem('wizard' + this.state.license.id));

          if (wizard_state != null) {
            this.state.wizard = wizard_state;
          }

        }).catch((error) => {
          this.state.license = {};
        });
      }

    },
    load_permissions: function () {

      axios.get('auth/permissions',
      ).then((response) => {
        var permission_list = [];
        for (var i = 0; i < response.data.length; i++) {
          permission_list.push(response.data[i].title);
        }
        this.state.permissions = permission_list;

      });
    },
    load_alerts: function () {
      return;
      var lastid = 0;
      if (typeof this.state.notifications != 'undefined') {
        if (this.state.notifications.length > 0) {
          lastid = this.state.notifications[0].id;
        }
      }
      axios.get('alerts/' + lastid,
      ).then((response) => {
        if (typeof response.data != 'undefined') {
          for (var i = 0; i < response.data.length; i++) {
            this.state.notifications.unshift(response.data[i]);
            if (this.state.notifications.length > 9) {
              this.state.notifications.pop();
            }
          }
        }
      });
    },
    has_permission: function (permission) {
      return this.state.permissions.includes(permission);


    }
  },
  watch: {
    $route(to, from) {
      this.state.globalsearch = '';
    }
  }
};
</script>

<style lang="scss">

@import '@/sass/app.scss';

</style>
