import Vue from 'vue'
import VueRouter from 'vue-router'
const Landing = () => import ('@/components/landing/Landing');



// Website
const Website = () => import ('@/components/website/Index');
const WebsiteApp = () => import ('@/components/website/views/App');
const WebsiteFeatures = () => import ('@/components/website/views/Features');
const WebsiteStatus = () => import ('@/components/website/views/Status');
const WebsiteSupport = () => import ('@/components/website/views/Support');
const WebsiteRegister = () => import ('@/components/website/views/Register');


// Auth components

const Register = () => import ('@/components/Register.vue');
const Login = () => import('@/components/Login.vue');
const Dashboard = () => import('@/components/Dashboard.vue');

const Profile = () => import( '@/components/Profile.vue');
const ProfileIndex = () => import( '@/components/profile/ProfileIndex.vue');
const ProfileEdit = () => import( '@/components/profile/ProfileEdit.vue');
const PageNotFound = () => import( '@/components/PageNotFound.vue');

const Settings = () => import( '@/components/settings/Settings.vue');
const SettingsLicense = () => import( '@/components/settings/SettingsLicense.vue');
const SettingsLicenseIndex = () => import( '@/components/settings/license/Index.vue');
const SettingsLicenseEdit = () => import( '@/components/settings/license/Edit.vue');
const SettingsLicenseBilling = () => import( '@/components/settings/license/Billing.vue');
const SecurityPermissionEditor = () => import( '@/components/settings/permissions/PermissionEditor.vue');
const SettingsUsers = () => import( '@/components/settings/SettingsUsers.vue');
const SettingsUsersIndex = () => import( '@/components/settings/users/Index.vue');
const SettingsUsersEdit = () => import( '@/components/settings/users/Edit.vue');
const SettingsUsersCreate = () => import( '@/components/settings/users/Create.vue');
const SettingsUsersShow = () => import( '@/components/settings/users/Show.vue');
const SettingsDocumentsTemplatesCreate = () => import( '@/components/settings/documents/TemplateCreate.vue');
const SettingsDocumentsTemplatesEdit = () => import( '@/components/settings/documents/TemplateEdit.vue');


const SettingsProcesses = () => import( '@/components/settings/SettingsProcesses.vue');

const SettingsDocuments = () => import( '@/components/settings/SettingsDocuments.vue');
const SettingsDocumentsIndex = () => import( '@/components/settings/documents/Index.vue');


const SettingsArticles = () => import( '@/components/settings/SettingsArticles.vue');
const SettingsArticlesIndex = () => import( '@/components/settings/articles/Index.vue');
const SettingsArticlesCreate = () => import( '@/components/settings/articles/ArticleCreate.vue');
const SettingsArticlesEdit = () => import( '@/components/settings/articles/ArticleEdit.vue');

const SettingsAccounts = () => import( '@/components/settings/SettingsAccounts.vue');
const SettingsAccountsIndex = () => import( '@/components/settings/accounts/Index.vue');
const SettingsAccountsCreate = () => import( '@/components/settings/accounts/AccountsCreate.vue');
const SettingsAccountsEdit = () => import( '@/components/settings/accounts/AccountsEdit.vue');

const SettingsCurrencies = () => import( '@/components/settings/SettingsCurrencies.vue');
const SettingsCurrenciesIndex = () => import( '@/components/settings/currencies/Index.vue');
const SettingsCurrenciesCreate = () => import( '@/components/settings/currencies/CurrenciesCreate.vue');
const SettingsCurrenciesEdit = () => import( '@/components/settings/currencies/CurrenciesEdit.vue');

const SettingsNumbering = () => import( '@/components/settings/SettingsNumbering.vue');
const SettingsNumberingIndex = () => import( '@/components/settings/numbering/Index.vue');

const SettingsAbsences = () => import( '@/components/settings/SettingsAbsences.vue');
const SettingsAbsencesIndex = () => import( '@/components/settings/absences/Index.vue');
const SettingsAbsencesCreate = () => import( '@/components/settings/absences/AbsencesCreate.vue');
const SettingsAbsencesEdit = () => import( '@/components/settings/absences/AbsencesEdit.vue');

const SettingsSubscriptions = () => import( '@/components/settings/SettingsSubscriptions.vue');
const SettingsSubscriptionsIndex = () => import( '@/components/settings/subscriptions/Index.vue');

const SettingsBanks = () => import( '@/components/settings/SettingsBanks.vue');
const SettingsBanksIndex = () => import( '@/components/settings/banks/Index.vue');
const SettingsBanksCreate = () => import( '@/components/settings/banks/BanksCreate.vue');
const SettingsBanksEdit = () => import( '@/components/settings/banks/BanksEdit.vue');

const SettingsUnits = () => import( '@/components/settings/SettingsUnits.vue');
const SettingsUnitsIndex = () => import( '@/components/settings/units/Index.vue');
const SettingsUnitsCreate = () => import( '@/components/settings/units/UnitsCreate.vue');
const SettingsUnitsEdit = () => import( '@/components/settings/units/UnitsEdit.vue');

const SettingsPaymentconditions = () => import( '@/components/settings/SettingsPaymentconditions.vue');
const SettingsPaymentconditionsIndex = () => import( '@/components/settings/paymentconditions/Index.vue');
const SettingsPaymentconditionsCreate = () => import( '@/components/settings/paymentconditions/PaymentconditionsCreate.vue');
const SettingsPaymentconditionsEdit = () => import( '@/components/settings/paymentconditions/PaymentconditionsEdit.vue');

const SettingsVatRates = () => import( '@/components/settings/SettingsVatRates.vue');
const SettingsVatRatesIndex = () => import( '@/components/settings/vatrates/Index.vue');
const SettingsVatRatesCreate = () => import( '@/components/settings/vatrates/VatRateCreate.vue');
const SettingsVatRatesEdit = () => import( '@/components/settings/vatrates/VatRateEdit.vue');

const SettingsArticleGroups = () => import( '@/components/settings/SettingsArticleGroups.vue');
const SettingsArticleGroupsIndex = () => import( '@/components/settings/articlegroups/Index.vue');
const SettingsArticleGroupsCreate = () => import( '@/components/settings/articlegroups/ArticleGroupsCreate.vue');
const SettingsArticleGroupsEdit = () => import( '@/components/settings/articlegroups/ArticleGroupsEdit.vue');

const Customers = () => import( '@/components/customers/Customers.vue');

const CustomersCreate = () => import( '@/components/customers/CustomersCreate.vue');
const CustomersIndex = () => import( '@/components/customers/CustomersIndex.vue');
const CustomersShow = () => import( '@/components/customers/CustomersShow.vue');
const CustomersEdit = () => import( '@/components/customers/CustomersEdit.vue');

const Documents = () => import( '@/components/documents/Documents.vue');
const DocumentsIndex = () => import( '@/components/documents/DocumentsIndex.vue');
const DocumentsCreate = () => import( '@/components/documents/DocumentsCreate.vue');
const DocumentsEdit = () => import( '@/components/documents/DocumentsEdit.vue');
const DocumentsShow = () => import( '@/components/documents/DocumentsShow.vue');
const DocumentsSelectType = () => import( '@/components/documents/DocumentsSelectType.vue');

const Contacts = () => import( '@/components/contacts/Contacts.vue');

const ContactsCreate = () => import( '@/components/contacts/ContactsCreate.vue');
const ContactsIndex = () => import( '@/components/contacts/ContactsIndex.vue');
const ContactsShow = () => import( '@/components/contacts/ContactsShow.vue');
const ContactsEdit = () => import( '@/components/contacts/ContactsEdit.vue');


const Objects = () => import( '@/components/objects/Objects.vue');
const ObjectsIndex = () => import( '@/components/objects/ObjectsIndex.vue');
const ObjectsShow = () => import( '@/components/objects/ObjectsShow.vue');
const ObjectsCreate = () => import( '@/components/objects/ObjectsCreate.vue');
const ObjectsEdit = () => import( '@/components/objects/ObjectsEdit.vue');

const Controlling = () => import( '@/components/controlling/Controlling.vue');
const ControllingIndex = () => import( '@/components/controlling/ControllingIndex.vue');
const ControllingTimetracking = () => import( '@/components/controlling/components/Timetracking.vue');
const ControllingDocuments = () => import( '@/components/controlling/components/Documents.vue');
const ControllingReport = () => import( '@/components/controlling/components/Report.vue');
const ControllingSalary = () => import( '@/components/controlling/components/Salary.vue');
const ControllingPayments = () => import( '@/components/controlling/components/Payments.vue');
const ControllingYield = () => import( '@/components/controlling/components/Yield.vue');
const ControllingLicenseHealth = () => import( '@/components/controlling/components/LicenseHealth.vue');

const MaterialCreate = () => import( '@/components/material/MaterialCreate.vue');
const MaterialEdit = () => import( '@/components/material/MaterialCreate.vue');
const MaterialShow = () => import( '@/components/material/MaterialShow.vue');

const Projects = () => import( '@/components/projects/Projects.vue');
const ProjectsIndex = () => import( '@/components/projects/ProjectsIndex.vue');
const ProjectsShow = () => import( '@/components/projects/ProjectsShow.vue');
const ProjectsCreate = () => import( '@/components/projects/ProjectsCreate.vue');
const ProjectsEdit = () => import( '@/components/projects/ProjectsEdit.vue');

const TimetrackingCreate = () => import(  '@/components/timetracking/Create.vue');
const QRTracking = () => import(  '@/components/timetracking/QRTracking.vue');

const Tasks = () => import(  '@/components/tasks/Tasks.vue');
const Kanban = () => import(  '@/components/kanban/KanbanTasks.vue');
const TasksIndex = () => import( '@/components/tasks/TasksIndex.vue');
const TasksShow = () => import( '@/components/tasks/TasksShow.vue');
const TasksCreate = () => import( '@/components/tasks/TasksCreate.vue');
const TasksEdit = () => import( '@/components/tasks/TasksEdit.vue');

const Contracts = () => import( '@/components/contracts/Contracts.vue');
const ContractsIndex = () => import( '@/components/contracts/ContractsIndex.vue');
const ContractsShow = () => import( '@/components/contracts/ContractsShow.vue');
const ContractsCreate = () => import( '@/components/contracts/ContractsCreate.vue');
const ContractsEdit = () => import('@/components/contracts/ContractsEdit.vue');


const Gantt = () => import( '@/components/gantt/Gantt.vue');

const Timeline = () => import( '@/components/timeline/Index.vue');

/* Landing Page */
const AGB = () => import('@/components/legal/agb.vue');
const Roadmap = () => import('@/components/landing/Roadmap.vue');
const Imprint = () => import('@/components/landing/Imprint');


/*
 * Process Pages
 *
 * These pages contain views that are accessible without login
 * and perform certain actions on tasks or projects for example
 * to mark them as completed.
 *
 */


const Process = () => import('@/components/process/Process.vue');
const TaskMarkAsCompleted = () => import('@/components/process/task/MarkAsCompleted.vue');
const ProjectMarkAsCompleted = () => import('@/components/process/project/MarkAsCompleted.vue');



const Alerts = () => import('@/components/alerts/Alerts.vue');


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing,
    meta: {
      auth: false
    }
  },
  {
    path: '/process',
    name: 'process',
    component: Process,
    meta: {
      auth: false
    },
    children: [
      {
        path: 'task/:task/complete',
        name: 'task_mark_as_complete',
        component: TaskMarkAsCompleted,
        meta: {
          auth: false
        }
      },
      {
        path: 'project/:project/complete',
        name: 'project_mark_as_complete',
        component: ProjectMarkAsCompleted,
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: '/website',
    name: 'website',
    component: Website,
    meta: {
      auth: false
    },
    children: [
      {
        path: '',
        name: 'website.index',
        component: WebsiteApp,
        meta: {
          auth: false
        },
      },
      {
        path: 'app',
        name: 'website.index',
        component: WebsiteApp,
        meta: {
          auth: false
        },
      },
      {
        path: 'features',
        name: 'website.features',
        component: WebsiteFeatures,
        meta: {
          auth: false
        },
      },
      {
        path: 'status',
        name: 'website.status',
        component: WebsiteStatus,
        meta: {
          auth: false
        },
      },
      {
        path: 'support',
        name: 'website.support',
        component: WebsiteSupport,
        meta: {
          auth: false
        },
      },
      {
        path: 'register',
        name: 'website.register',
        component: WebsiteRegister,
        meta: {
          auth: false
        },
      },
    ]
  },
  {
    path: '/agb',
    name: 'agb',
    component: AGB,
    meta: {
      auth: false
    }
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: Roadmap,
    meta: {
      auth: false
    }
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint,
    meta: {
      auth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      auth: false
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Alerts,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Landing,
    meta: {
      auth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: Gantt,
    meta: {
      auth: true
    }
  },
  {
    path: '/timerecord',
    name: 'timerecord',
    component: TimetrackingCreate,
    meta: {
      auth: true
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline,
    meta: {
      auth: true
    }
  },
  {
    path: '/timerecord/edit/:editid',
    name: 'timerecordedit',
    component: TimetrackingCreate,
    meta: {
      auth: true
    }
  },
  {
    path: '/qrtracking',
    name: 'qrtracking',
    component: QRTracking,
    meta: {
      auth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: SettingsUsers,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'users.index',
        component: SettingsUsersIndex,
        meta: {
          auth: true
        },
      },
      {
        path: 'create',
        name: 'users.create',
        component: SettingsUsersCreate,
        props: {edit: false},
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'users.edit',
        component: SettingsUsersEdit,
        props: {edit: true},
        meta: {
          auth: true
        },
      },
      {
        path: ':id',
        name: 'users.show',
        component: SettingsUsersShow,
        meta: {
          auth: true
        },
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'settings.license',
        component: SettingsLicense,
        meta: {
          auth: true
        },
      },
      {
        path: 'license/billing',
        name: 'settings.license.billing',
        component: SettingsLicenseBilling,
        meta: {
          auth: true
        },
      },
      {
        path: 'processes',
        name: 'settings.processes',
        component: SettingsProcesses,
        meta: {
          auth: true
        },
      },
      {
        path: 'license',
        name: 'settings.license',
        component: SettingsLicense,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.license.index',
            component: SettingsLicenseIndex,
            meta: {
              auth: true
            },
          },
          {
            path: 'edit',
            name: 'settings.license.edit',
            component: SettingsLicenseEdit,
            meta: {
              auth: true
            },
          }

        ]
      },
      {
        path: 'security',
        name: 'settings.security',
        component: SecurityPermissionEditor,
        meta: {
          auth: true
        },
      },
      {
        path: 'articles',
        name: 'settings.articles',
        component: SettingsArticles,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.articles.index',
            component: SettingsArticlesIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.articles.create',
            component: SettingsArticlesCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.articles.edit',
            component: SettingsArticlesEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'accounts',
        name: 'settings.accounts',
        component: SettingsAccounts,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.accounts.index',
            component: SettingsAccountsIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.accounts.create',
            component: SettingsAccountsCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.accounts.edit',
            component: SettingsAccountsEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'currencies',
        name: 'settings.currencies',
        component: SettingsCurrencies,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.currencies.index',
            component: SettingsCurrenciesIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.currencies.create',
            component: SettingsCurrenciesCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.currencies.edit',
            component: SettingsCurrenciesEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'absences',
        name: 'settings.absences',
        component: SettingsAbsences,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.absences.index',
            component: SettingsAbsencesIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.absences.create',
            component: SettingsAbsencesCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.absences.edit',
            component: SettingsAbsencesEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'banks',
        name: 'settings.banks',
        component: SettingsBanks,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.banks.index',
            component: SettingsBanksIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.banks.create',
            component: SettingsBanksCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.banks.edit',
            component: SettingsBanksEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'subscriptions',
        name: 'settings.subscriptions',
        component: SettingsSubscriptions,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.banks.index',
            component: SettingsSubscriptionsIndex,
            meta: {
              auth: true
            },
          }
        ]

      },
      {
        path: 'units',
        name: 'settings.units',
        component: SettingsUnits,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.units.index',
            component: SettingsUnitsIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.units.create',
            component: SettingsUnitsCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.units.edit',
            component: SettingsUnitsEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'paymentconditions',
        name: 'settings.paymentconditions',
        component: SettingsPaymentconditions,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.paymentconditions.index',
            component: SettingsPaymentconditionsIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.paymentconditions.create',
            component: SettingsPaymentconditionsCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.paymentconditions.edit',
            component: SettingsPaymentconditionsEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'vatrates',
        name: 'settings.vatrates',
        component: SettingsVatRates,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.vatrates.index',
            component: SettingsVatRatesIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.vatrates.create',
            component: SettingsVatRatesCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.vatrates.edit',
            component: SettingsVatRatesEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'articlegroups',
        name: 'settings.articlegroups',
        component: SettingsArticleGroups,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.articlegroups.index',
            component: SettingsArticleGroupsIndex,
            meta: {
              auth: true
            },
          },{
            path: 'create',
            name: 'settings.articlegroups.create',
            component: SettingsArticleGroupsCreate,
            meta: {
              auth: true
            },
          },{
            path: ':id',
            name: 'settings.articlegroups.edit',
            component: SettingsArticleGroupsEdit,
            meta: {
              auth: true
            },
          },
        ]

      },
      {
        path: 'documents',
        name: 'settings.documents',
        component: SettingsDocuments,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.documents.index',
            component: SettingsDocumentsIndex,
            meta: {
              auth: true
            },
          },

          {
            path: 'template/create',
            name: 'settings.documents.templates.create',
            component: SettingsDocumentsTemplatesCreate,
            meta: {
              auth: true
            },
          },

          {
            path: 'templates/:id',
            name: 'settings.documents.templates.edit',
            component: SettingsDocumentsTemplatesEdit,
            meta: {
              auth: true
            },
          }
        ]
      },
      {
        path: 'currencies',
        name: 'settings.currencies',
        component: SettingsCurrencies,
        meta: {
          auth: true
        }
      },
      {
        path: 'numbering',
        name: 'settings.numbering',
        component: SettingsNumbering,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.numbering.index',
            component: SettingsNumberingIndex,
            meta: {
              auth: true
            }
          }
          ]
      },
      {
        path: 'users',
        name: 'settings.users',
        component: SettingsUsers,
        meta: {
          auth: true
        },
        children: [
          {
            path: '',
            name: 'settings.users.index',
            component: SettingsUsersIndex,
            meta: {
              auth: true
            },
          },
          {
            path: 'create',
            name: 'settings.users.create',
            component: SettingsUsersCreate,
            props: {edit: false},
            meta: {
              auth: true
            },
          },
          {
            path: ':id/edit',
            name: 'settings.users.edit',
            component: SettingsUsersEdit,
            props: {edit: true},
            meta: {
              auth: true
            },
          },
          {
            path: ':id',
            name: 'settings.users.show',
            component: SettingsUsersShow,
            meta: {
              auth: true
            },
          }
        ]
      }
    ]
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'customers.index',
        component: CustomersIndex,
        meta: {
          auth: true
        },
      },
      {
        path: 'create',
        name: 'customers.create',
        component: CustomersCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'customers.edit',
        component: CustomersEdit,
        meta: {
          auth: true
        },
      },
      {
        path: ':id',
        name: 'customers.show',
        component: CustomersShow,
        meta: {
          auth: true
        },
      }
    ]
  }, {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'contacts.index',
        component: ContactsIndex,
        meta: {
          auth: true
        },
      },
      {
        path: 'create',
        name: 'contacts.create',
        component: ContactsCreate,
        meta: {
          auth: true
        },
      },
      {
        path: 'create/:customer_id',
        name: 'contacts.store',
        component: ContactsCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'contacts.edit',
        component: ContactsEdit,
        meta: {
          auth: true
        },
      },
      {
        path: ':id',
        name: 'contacts.show',
        component: ContactsShow,
        meta: {
          auth: true
        },
      }
    ]
  }, {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'profile.index',
        component: ProfileIndex,
        meta: {
          auth: true
        }
      }, {
        path: 'edit',
        name: 'profile.edit',
        component: ProfileEdit,
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/kanban',
    name: 'kanban',
    component: Kanban,
    meta: {
      auth: true
    },
    children: [
      {
        path: ':task_id',
        name: 'kanban.show',
        component: Kanban,
        meta: {
          auth: true
        }
      }
    ]

  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'documents.index',
        component: DocumentsIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'create',
        name: 'documents.selecttype',
        component: DocumentsSelectType,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:type',
        name: 'documents.create',
        component: DocumentsCreate,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'documents.edit',
        component: DocumentsEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id',
        name: 'documents.show',
        component: DocumentsShow,
        meta: {
          auth: true
        }
      },
    ]
  },
  {
    path: '/controlling',
    name: 'controlling',
    component: Controlling,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'controlling.index',
        component: ControllingTimetracking,
        meta: {
          auth: true
        }
      },
      {
        path: 'timetracking',
        name: 'controlling.timetracking',
        component: ControllingTimetracking,
        meta: {
          auth: true
        }
      },
      {
        path: 'documents',
        name: 'controlling.documents',
        component: ControllingDocuments,
        meta: {
          auth: true
        }
      },
      {
        path: 'report',
        name: 'controlling.report',
        component: ControllingReport,
        meta: {
          auth: true
        }
      },
      {
        path: 'salary',
        name: 'controlling.salary',
        component: ControllingSalary,
        meta: {
          auth: true
        }
      },
      {
        path: 'paymentmonitoring',
        name: 'controlling.paymentmonitoring',
        component: ControllingPayments,
        meta: {
          auth: true
        }
      },
      {
        path: 'yield',
        name: 'controlling.yield',
        component: ControllingYield,
        meta: {
          auth: true
        }
      },
      {
        path: 'licensehealth',
        name: 'controlling.licensehealth',
        component: ControllingLicenseHealth,
        meta: {
          auth: true
        }
      },
    ]
  },
  {
    path: '/material',
    name: 'material',
    component: Objects,
    meta: {
      auth: true
    },
    children: [
      {
        path: 'create/:object_id',
        name: 'material.create',
        component: MaterialCreate,
        meta: {
          auth: true
        }
      },

      {
        path: ':id',
        name: 'material.show',
        component: MaterialShow,
        meta: {
          auth: true
        }
      },

      {
        path: ':id/edit',
        name: 'material.edit',
        component: MaterialEdit,
        meta: {
          auth: true
        }
      }
    ],
  },
  {
    path: '/objects',
    name: 'objects',
    component: Objects,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'objects.index',
        component: ObjectsIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:id/material',
        name: 'objects.create.material',
        component: MaterialCreate,
        meta: {
          auth: true
        }
      },
      {
        path: 'create',
        name: 'objects.create',
        component: ObjectsCreate,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:customer_id',
        name: 'objects.store',
        component: ObjectsCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'objects.edit',
        component: ObjectsEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id',
        name: 'objects.show',
        component: ObjectsShow,
        meta: {
          auth: true
        }
      },
    ]
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'projects.index',
        component: ProjectsIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'create',
        name: 'projects.create',
        component: ProjectsCreate,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:project_id',
        name: 'projects.store',
        component: ProjectsCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'projects.edit',
        component: ProjectsEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id',
        name: 'projects.show',
        component: ProjectsShow,
        meta: {
          auth: true
        }
      },
    ]
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'tasks.index',
        component: TasksIndex,
        meta: {
          auth: true,
          view: 'list'
        }
      },
      {
        path: 'kanban',
        name: 'tasks.index',
        component: TasksIndex,
        meta: {
          auth: true,
          view: 'kanban'
        }
      },
      {
        path: 'create',
        name: 'tasks.create',
        component: TasksCreate,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:kanban_state',
        name: 'tasks.store',
        component: TasksCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'tasks.edit',
        component: TasksEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id',
        name: 'tasks.show',
        component: TasksShow,
        meta: {
          auth: true
        }
      },

    ]
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: Contracts,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'contracts.index',
        component: ContractsIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'create',
        name: 'contracts.create',
        component: ContractsCreate,
        meta: {
          auth: true
        }
      },
      {
        path: 'create/:contract_id',
        name: 'contracts.store',
        component: ContractsCreate,
        meta: {
          auth: true
        },
      },
      {
        path: ':id/edit',
        name: 'contracts.edit',
        component: ContractsEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id',
        name: 'contracts.show',
        component: ContractsShow,
        meta: {
          auth: true
        }
      },
    ]
  },

  {
    path: '/storage/*'
  },
  {
    path: "*",
    component: PageNotFound,
    name: 'not found',
    meta: {
      auth: true
    }
  }


]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  linkActiveClass: 'is-active',
  routes
})

export default router
