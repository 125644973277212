<template>
  <div class="license-wizard" v-bind:class="{ minimized: state.wizard.minimized === true }">
    <div class="wizard-content">
      <div class="shield">

      </div>
      <div class="wizard-close" :title="$t('close wizard')" @click="close_wizard">
        &times;
      </div>
      <div class="wizard-minimize" :title="$t('minimize wizard')" @click="minimize_wizard">
        _
      </div>
      <div class="wizard-info">
        <div class="wizard-microtitle">{{ $t('license wizard') }}</div>
        <div class="wizard-title">
          <template v-if="typeof steps[state.wizard.step].title != 'undefined'">
            {{ $t('wizard.' + steps[state.wizard.step].title) }}
          </template>
        </div>

        <div class="wizard-description">
          <template v-if="typeof steps[state.wizard.step].title != 'undefined'">
            {{ $t('wizard.' + steps[state.wizard.step].description, { data: steps[state.wizard.step].data }) }}
          </template>
          <template
              v-if="typeof steps[state.wizard.step].action != 'undefined' && steps[state.wizard.step].action != null">
            <br><br>
            <div class="btn primary small" @click="$router.push(steps[state.wizard.step].action)">
              {{ $t(steps[state.wizard.step].action_title) }}
            </div>
          </template>
        </div>
      </div>

      <div class="wizard-progress-container">
        <div class="wizard-progress">
          <div class="wizard-progress-steps">
            <div class="wizard-progress-step-markers" @click="goto_step(itm)" :title="$t('wizard.go to step n',{step: itm + 1}) + ', ' + $t('wizard.' + steps[itm].title)" v-for="itm in steps.length - 1"></div>
          </div>
          <div class="wizard-progess-complete" v-bind:class="{ completed: is_last }"
               v-bind:style="{ width: progress + '%' } "></div>
        </div>
        <div class="wizard-step-info">
          <div @click="previous_step" class="step-icon" v-bind:class="{ disabled: is_first}">
            <svgimg name="chevron-left"></svgimg>
          </div>
          {{ state.wizard.step + 1 }} / {{ steps.length }}
          <div @click="next_step" class="step-icon" v-bind:class="{ disabled: is_last}">
            <svgimg name="chevron-right"></svgimg>
          </div>
        </div>
      </div>
    </div>
    <div class="wizard-minimized" @click="maximize_wizard" :title="$t('maximize wizard')">
      <svgimg name="resize"></svgimg>
    </div>
  </div>
</template>
<script>

import svgimg from "../svgimg";

export default {
  components: {
    svgimg
  },
  props: ['state'],
  data() {
    return {
      steps: [
        {
          title: 'welcome',
          description: 'welcome description',
          url: '/dashboard'
        },

        {
          title: 'review license',
          description: 'review license description',
          url: '/settings/license',
          action: '/settings/license/edit',
          action_title: 'edit',
          data: this.state.license.title
        },



        {
          title: 'manage permissions',
          description: 'manage permissions description',
          url: '/settings/security',
        },

        {
          title: 'employees',
          description: 'employees description',
          url: '/users',
          action: '/users/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'currencies',
          description: 'currencies description',
          url: '/settings/currencies',
          action: '/settings/currencies/create',
          action_title: 'create',
          data: this.state.license.title
        },

        {
          title: 'units',
          description: 'units description',
          url: '/settings/units',
          action: '/settings/units/create',
          action_title: 'create',
          data: this.state.license.title
        },


        {
          title: 'vatrates',
          description: 'vatrates description',
          url: '/settings/vatrates',
          data: this.state.license.title
        },

        {
          title: 'banks',
          description: 'banks description',
          url: '/settings/banks',
          action: '/settings/banks/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'payment conditions',
          description: 'payment conditions description',
          url: '/settings/paymentconditions',
          action: '/settings/paymentconditions/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'accounts',
          description: 'accounts description',
          url: '/settings/accounts',
          action: '/settings/accounts/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'numbering',
          description: 'numbering description',
          url: '/settings/numbering',
          data: this.state.license.title
        },
        {
          title: 'article groups',
          description: 'article groups description',
          url: '/settings/articlegroups',
          action: '/settings/articlegroups/create',
          action_title: 'create'
        },
        {
          title: 'articles',
          description: 'articles description',
          url: '/settings/articles',
          action: '/settings/articles/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'absences',
          description: 'absences description',
          url: '/settings/absences',
          action: '/settings/absences/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'documents',
          description: 'absences description',
          url: '/settings/documents',
          data: this.state.license.title
        },
        {
          title: 'document templates',
          description: 'document template description',
          url: '/settings/documents',
          action: '/settings/documents/template/create',
          action_title: 'create',
          data: this.state.license.title
        },



        {
          title: 'customers',
          description: 'customers description',
          url: '/customers',
          action: '/customers/create',
          action_title: 'create',
          data: this.state.license.title
        },

        {
          title: 'contacts',
          description: 'contacts description',
          url: '/contacts',
          action: '/contacts/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'objects',
          description: 'objects description',
          url: '/objects',
          action: '/objects/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'projects',
          description: 'projects description',
          url: '/projects',
          action: '/projects/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'contracts',
          description: 'contracts description',
          url: '/contracts',
          action: '/contracts/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'tasks',
          description: 'tasks description',
          url: '/tasks',
          action: '/tasks/create',
          action_title: 'create',
          data: this.state.license.title
        },
        {
          title: 'controlling timetracking',
          description: 'controlling timetracking description',
          url: '/controlling/timetracking',
          data: this.state.license.title
        },
        {
          title: 'controlling documents',
          description: 'controlling documents description',
          url: '/controlling/documents',
          data: this.state.license.title
        },
        {
          title: 'controlling report',
          description: 'controlling report description',
          url: '/controlling/report',
          data: this.state.license.title
        },
        {
          title: 'controlling salary',
          description: 'controlling salary description',
          url: '/controlling/salary',
          data: this.state.license.title
        },

        {
          title: 'completed',
          description: 'completed description',
          url: null
        },

      ]
    }
  },
  computed: {
    is_last: function () {
      return (this.state.wizard.step == this.steps.length - 1);
    },
    is_first: function () {
      return this.state.wizard.step == 0;
    },
    progress: function () {
      return 100 / (this.steps.length - 1) * this.state.wizard.step;
    }
  },
  methods: {
    navigate_to: function () {
      if (typeof this.steps[this.state.wizard.step].url != 'undefined' && this.steps[this.state.wizard.step].url != null) {
        this.$router.push(this.steps[this.state.wizard.step].url);
      }
    },
    store_state: function () {
      localStorage.setItem('wizard' + this.state.license.id, JSON.stringify(this.state.wizard));
    },
    close_wizard: function () {
      this.state.wizard.enabled = false;
      this.store_state();
    },
    minimize_wizard: function () {
      this.state.wizard.minimized = true
      this.store_state();
    },
    maximize_wizard: function () {
      this.state.wizard.minimized = false
      this.store_state();
    },
    next_step: function () {
      if (this.state.wizard.step < this.steps.length) {
        this.state.wizard.step++;
      }
      this.store_state();
      this.navigate_to();
      return this.state.wizard.step;
    },
    goto_step: function(step){
      this.state.wizard.step = step;
      this.store_state();
      this.navigate_to();
      return this.state.wizard.step;
    },
    previous_step: function () {
      if (this.state.wizard.step > 0) {
        this.state.wizard.step--;
      }

      this.store_state();
      this.navigate_to();
      return this.state.wizard.step;
    }
  }
}
</script>
