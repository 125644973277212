<template>
    <div class="input-group">
        <label class="label-small">{{$t(title)}}</label>
        <select class="input" v-model="values">
            <option v-for="(item, index) in countrylist"  :key="index" :value="item.code">{{$t('countries.' + item.code)}}</option>
        </select>
    </div>

</template>


<script>

    export default {
        props: ['value','title'],
        data() {
            return {
                values: this.value,
                countrylist: [],
            }
        },
        watch: {
            values: function () {
                this.$emit('input', this.values);

            },
            value: function(){
                this.values = this.value;
            }
        },
        created: function(){
            this.load_countries();
        },
        methods: {
            load_countries: function(){

                axios.get('/countries').then((response) => {
                   this.countrylist = response.data;
                   this.values = this.value;
                });

            }
        }
    }
</script>