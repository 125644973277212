import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import axios from "axios";
import VueDayjs from 'vue-dayjs-plugin'
import Datepicker from 'vuejs-datepicker';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueI18n from 'vue-i18n';
import messages from './lang';
import { VueEditor } from "vue2-editor";
import VueQrcodeReader from "vue-qrcode-reader";
import svgimg from '@/components/svgimg.vue';
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueI18n);

// LogRocket.init('yoi6ic/cpa');


window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_APIROOT;
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const i18n = new VueI18n({
  locale: 'de',
  silentTranslationWarn: true,
  fallbackLocale: 'de',
  messages
});

Vue.use(VueDayjs, {
  lang: i18n.locale
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDFbmeDXoAsS1py2Dx14L6Rn0WOFwgOJGA',// This is required if you use the Autocomplete plugin
    libraries: 'places',
  },

})

Vue.use( VueEditor );
Vue.use( VueQrcodeReader );

//Vue.component('v-chart', ECharts);

new Vue({
  router,
  i18n,
  axios,
  VueGoogleMaps,
  svgimg,
  VueDayjs,
  Datepicker,
  VueEditor,
  render: h => h(App)
}).$mount('#app')

