<template>
    <div @click="switchstate">
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs></defs><path class="cls-1" d="M35.32,11.92H12.77a12.68,12.68,0,0,0,0,25.36H35.32a12.68,12.68,0,0,0,0-25.36Zm0,22.56a9.88,9.88,0,1,1,9.87-9.88A9.89,9.89,0,0,1,35.32,34.48Z"/></svg>
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs></defs><path class="cls-2" d="M35.32,11.92H12.77a12.68,12.68,0,0,0,0,25.36H35.32a12.68,12.68,0,0,0,0-25.36Zm-22,22.56a9.88,9.88,0,1,1,9.87-9.88A9.89,9.89,0,0,1,13.29,34.48Z"/></svg>
    </div>
</template>


<script>
export default {
    props: ['value','disabled'],
    data() {
        return {
            input: this.value
        }
    },
    methods: {
        switchstate: function(){
            if(this.disabled == false){
                this.input = !this.input;
            }
            this.$emit('input',input);
        }
    }
}

</script>

<style>
.cls-1{fill:#a7a9ac;}
.cls-2{fill:#8dc63f;}
</style>
