<template>
    <div class="cookie-notice" v-if="!cookies_accepted">
        <div class="cookie-notice-text">
            {{$t('cookie info')}}
        </div>
        <div @click="accept" class="cookie-notice-action">
            {{ $t('ok') }}
        </div>
    </div>
</template>
<script>
export default {

    data: function(){
        return {
            cookies_accepted: localStorage.getItem("cookies.accepted") || false,
        }
    },
    methods: {
        accept: function(){
            localStorage.setItem("cookies.accepted",true);
            this.cookies_accepted = true;
        }
    }
}

</script>
