<template>
    <div class="profile-dropdown" v-if="state.user != null">


        <div class="mobile-navbar">
          <div class="main-navigation">
            <router-link v-if="state.permissions.includes(item.permission)" :title="$t(item.title)"
                         :to="item.url" class="nav-item" :key="index"
                         v-for="(item, index) in state.navigation">
              <div class="nav-icon">
                <svgimg :name="item.icon" :prevent_invert="true"></svgimg>
              </div>
              <div class="nav-label">{{ $t(item.title) }}</div>
            </router-link>
          </div>

        </div>
        <div class="profile-dropdown-element no-hover">


            <div class="hours-progerss-label">
                <strong>{{ $t('workhours today') }}</strong>
                <br>
                <template v-if="working_hours!=null">
                    {{ time_format(working_hours.difference.working_hours) }} {{ $t('hour abbrev') }} /
                    {{ time_format(working_hours.difference.target_hours) }} {{ $t('hour abbrev') }}
                </template>
                <template v-else>
                    - / -
                </template>
            </div>

            <div class="hours-progress-bar">
                <div class="progress-bar-filled" v-bind:style=" { width: hours_percentage + '%' }"></div>
            </div>
        </div>

        <div class="profile-dropdown-element no-hover">
            <div class="hours-progerss-label">
                <strong>{{ $t('workhour balance') }}</strong>
                <br>
                <template v-if="working_hours!=null">
                    {{ time_format(working_hours.difference.flex_hours) }} {{ $t('hour abbrev') }}
                </template>
                <template v-else>
                    -
                </template>
            </div>
        </div>

        <div class="profile-dropdown-element no-hover">
            <div class="hours-progerss-label">
                <strong>{{ $t('holidays balance') }}</strong>
                <br>
                <template v-if="working_hours!=null">
                    {{ time_format(working_hours.at_end.flex_holidays) }} {{ $t('hour abbrev') }}
                </template>
                <template v-else>
                    -
                </template>
            </div>
        </div>


        <div @click="profile" class="profile-dropdown-element">
            {{$t('profile')}}
        </div>
        <div @click="logout" class="profile-dropdown-element">
            {{$t('sign out')}}
        </div>
    </div>
</template>
<script>

import svgimg from "../svgimg";
export default {

    props: ['state'],
    data() {
        return {
            working_hours: null
        }

    },
    mounted: function () {
        this.load_work_hours();
    },
    computed: {
        hours_percentage: function () {
            if (this.working_hours != null) {
                return Math.min(100 / this.working_hours.difference.target_hours * this.working_hours.difference.working_hours, 100);
            } else {
                return 0;
            }
        }
    },
    methods: {
        time_format: function (hours) {

            return parseFloat(hours).toFixed(2);
            var sign = hours >= 0;

            hours = Math.abs(hours);
            var fullhours = Math.floor(hours);
            var seconds = Math.floor(parseFloat(hours - fullhours) * 60);

            return ((sign) ? '' : '-') + fullhours + ":" + ("0" + seconds).slice(-2);
        },
        load_work_hours: function () {

            axios.get('employees/working_hours_balance/today').then((response) => {
                this.working_hours = response.data;
            });
        },
        profile: function () {
            this.$router.push('/profile');
        },
        logout: function () {
            this.$emit('logout');
        }
    },
  components: {
    svgimg
  }
}
</script>
