<template>
    <div>


        <!-- Register Form -->
      <!--
        <template v-if="register">
            <div class="landing-section">

                <div class="landing-row">
                    <Register></Register>

                    <div class="card-links"><br>
                        {{$t('already registered?')}} <a href="#" @click="register=false">{{$t('login')}}</a>
                    </div>

                    <br><br><br>


                </div>
            </div>

            <div class="landing-section landing-white">
                <div class="landing-row">
                    <div class="row">
                        <div class="col">
                            <div class="card">

                                <span class="btn secondary small" @click="register=false">Zurück</span>
                                <div class="nav-title">{{$t('register license')}}</div>

                                <div class="property-group">
                                    <div class="input-group" >
                                        <label class="label-small">{{$t('license title')}}</label>
                                        <input type="text" class="input" v-model="register_data.licensename">
                                    </div>
                                </div>

                                <div class="property-group">
                                    <div class="input-group" >
                                        <label class="label-small">{{$t('email')}}</label>
                                        <input type="text" class="input" v-model="register_data.email">
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-action">
                                    <div class="btn primary" @click="save">{{$t('register')}}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </template>
-->

        <!-- Landing Page -->
        <template v-if="!register">

            <div class="landing-section landing-white">
                <div class="landing-row">
                    <div class="main-headline">
                        <!-- <h1>Aufträge, Abos und Controlling
                            für moderne Facility Manager</h1> -->

                    </div>
                </div>
                <div class="landing-row">
                    <div class="landing-columns">
                        <div style="    flex: 1 1 auto;">
                          <!--   <p>
                                Eine saubere Sache, wenn es um die Verwaltung des Betriebes in der Unterhalts- und
                                Reinigungsbranche geht. CleanPro App bietet alle Vorteile des mobilen Zugriffs &ndash;
                                jederzeit verfügbar und das zum wegweisenden Preisleistungsverhältnis.
                            </p>
                          -->
                            <!--  <div>
                                 <br>
                                 <button class="btn primary" style="max-width:240px;" @click="register=true">Kostenlos testen</button>
                                 <br><br>
                                 <p class="notice">Bereits Kunde? <span class="link"
                                                                        @click="showlogin=true">Anmelden</span>
                                 </p>
                             </div> -->

                            <div class="login-container">
                                <Login @toast="toast" @login="login"></Login>
                            </div>

                           <!--  Noch nicht registriert? <a href="#" @click="register=true">Jetzt kostenlos testen.</a> -->

                        </div>
                        <div class="imagery">
                           <!--  <img src="/images/landing/facility_manager_2x.png"> -->
                        </div>
                    </div>
                </div>
            </div>

<!--
            <div class="landing-separator">&nbsp;

            </div>
            <div class="landing-section bgimage">
                <div class="centered-absolute">
                    <img src="/images/landing/art-box-1.svg" alt="">
                </div>
                <img src="/images/landing/top-separator-db.svg" class="blue-top">
            </div>
            <div class="landing-section blue">
                <div class="landing-row">
                    <h2 style="padding-top:74px;">Ab in die Zukunft</h2>
                </div>
                <div class="landing-row">
                    <div class="landing-columns">
                        <p class="min-height">Mit der modernsten Web Plattform verwandeln Sie jedes Ihrer Geräte in ein
                            Sammelpunkt des Wissens
                            über Ihr Unternehmen - und das ganz ohne Installation.</p>
                    </div>

                </div>
            </div>
            <div class="landing-section bgimage">
                <img src="/images/landing/bottom-separator-db.svg" class="blue-top">
            </div>
            <div class="landing-section">
                <div class="landing-row absolute-parent">
                    <img class="device-preview" src="/images/landing/device_01.png">
                </div>
            </div>


            <div class="landing-separator">
            </div>
            <div class="landing-section">
                <div class="landing-row">

                    <div class="landing-columns">
                        <div class="fifty-column">&nbsp;</div>
                        <div>
                            <h2>Blitzschnell</h2>
                            <p>
                                Mit wenigen Klicks erfahren Sie alle Details bis zum Zählerstand der Heizung von Frau
                                Meier
                                im 3. OG. Erstellen Sie im gleichen Zug eine Aufgabe für Ihren Teamkollegen und er
                                erhält
                                eine Benachrichtigung per SMS oder E-Mail.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="landing-separator">

            </div>
            <div class="landing-section">
                <div class="landing-row centered">

                    <h2>Mit CleanPro App sind Sie klar im Vorteil auch mit den Kosten.</h2>
                </div>

                <div class="landing-row centered">
                    <div class="license-details">
                        <h3>Ihre CleanPro App Lizenz</h3>

                        <br>


                        <table class="pricing-table">
                            <tr>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" disabled checked>
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td><strong>{{$t('base programm')}}</strong></td>
                                <td class="right module_price active">{{billing_price(prices.baseprice)}}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td><strong>{{$t('additional modules')}}</strong></td>
                                <td class="right"></td>
                            </tr>
                            <tr v-for="(item, index) in prices.modules">

                                <td>
                                    <label class="switch">
                                        <input type="checkbox" v-model="item.checked">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td>{{$t(item.title)}}</td>
                                <td class="right module_price" v-bind:class="{ active : item.checked }">
                                  {{billing_price(item.price)}}
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td colspan="3" class="right">
                                    <small>{{$t('available as')}}</small>
                                    <div class="license-duration"><span @click="pricetype='monthly'"
                                                                        v-bind:class="{ is_active: pricetype == 'monthly'}">{{$t('monthly subscription')}}</span>
                                        oder <span @click="pricetype='yearly'"
                                                   v-bind:class="{ is_active: pricetype == 'yearly'}">{{$t('yearly subscription')}}</span>
                                    </div>

                                </td>
                            </tr>
                            <tr>

                                <td colspan="3" class="right">
                                    <div class="price-tag">{{billing_price(total_price)}}<br>
                                        <small>{{$t('prices excluding vat')}}</small>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <button class="btn block secondary" @click="register=true">{{$t('order now')}}</button>
                                </td>
                            </tr>
                        </table>

                    </div>

                </div>


                <br><br><br>
            </div>


            <div class="landing-separator">

            </div>

            <div class="landing-section centered cta">
                <div class="large-cta">Noch offene Fragen?</div>
                <div class="cta-description">CleanPro Support unterstützt Sie gerne unter <a
                    href="mailto:support@cleanpro.app">support@cleanpro.app</a></div>
                <div class="support-img">
                    <img src="/images/landing/support.svg" alt="Support">
                </div>
            </div>
          -->
        </template>


        <!--  <div class="landing-inner">
             <div class="landing-view">
                 <div class="card">
                     <Login></Login>
                 </div>
             </div>
         </div> -->

    </div>
</template>

<script>


import Svgimg from "@/components/svgimg";
import Login from "@/components/Login";
import Register from "@/components/Register";
import Switcher from "@/components/helpers/Switcher";

export default {
    data() {
        return {
            email: null,
            password: null,
            error: false,
            showlogin: false,
            pricetype: 'monthly',
            register: false,
            detailled_features: false,
            register_data: {
                licensename: '',
                email: ''
            },
            prices: { /* Monthly Prices. Year = 10 Months */
                baseprice: 199,
                modules: [
                    {checked: false, title: 'module.salary', price: 49},
                    {checked: false, title: 'module.billing', price: 49},
                    {checked: false, title: 'module.ressources', price: 49},
                    {checked: false, title: 'module.unlimited', price: 39},
                ]
            }
        }
    },
    components: {
        Svgimg,
        Login,
        Register,
        Switcher
    },
    computed: {
      concurrency: function(){
        return navigator;
      },
        total_price: function () {
            var total = this.prices.baseprice;
            for (var i = 0; i < this.prices.modules.length; i++) {
                if (this.prices.modules[i].checked) {
                    total += this.prices.modules[i].price;
                }
            }
            return total;
        },
        pricename: function () {
            if (this.pricetype == 'yearly') {
                return "CHF 2'199.00 / Jahr";
            } else {
                return "CHF 199.00 / Monat";
            }
        },
    },
    methods: {
        billing_price: function (price) {
            if (this.pricetype == 'monthly') {
                return this.format_price(price);
            } else {
                return this.format_price(price * 11);
            }
        },
        format_price: function (price, currency) {


          if(isNaN(price)){
            return '';
          }

            if (typeof currency == 'undefined' || currency == null || !isNaN(currency)) {
                currency = 'CHF';
            }
            var formatter = new Intl.NumberFormat('de-CH', {
                style: 'currency',
                currency: currency,
            });

            return formatter.format(price); /* $2,500.00 */
        },
        toast(data) {
            this.$emit('toast', data);
        },
        login(data) {
            this.$emit('login',data);

        },
    }
}
</script>
<style>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #69b547;
}

input:checked:disabled + .slider {
    background-color: #bad696;
}

input:focus + .slider {
    box-shadow: 0 0 1px #69b547;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
