<template>
  <div class="helpsystem-container" >
    <div class="helpsystem-content">
      <div class="help-close">
          <span class="clickable" @click.self="$emit('helpclose');">&times;</span>
      </div>
      <div class="help-heading">
        <div class="help-headline">{{ $t('how can we help') }}</div>
        <input class="help-search" v-model="searchquery" type="text" @change="validate" @keyup="validate"
               @keydown.enter="search()" :placeholder="$t('search in help')">
      </div>
      <div class="help-textarea">

        <template v-if="(state.help.topic == 'index' && this.searchresults.length == 0) || article == null">
          <HelpTopics></HelpTopics>
          <div class="separator"></div>
        </template>

        <HelpArticle :loading="loading" :state="state" :article="article" v-if="state.help.topic != 'index'"></HelpArticle>

        <Loader v-if="loading_search"></Loader>
        <div class="help-search-results" v-if="searchresults.length > 0 && state.help.topic == 'index'">
          <div class="help-result" v-for="(item, index) in searchresults" @click="
        $emit('help', item.slug); load_topic(item.slug)">


            <div class="help-article-preview-url">
              <span class="url-prefix">{{ envir }}</span> <span class="breadcrumb-separator">&gt;</span> <span class="url-step">help</span><span class="breadcrumb-separator">&gt;</span><span class="url-step">{{ item.slug }}</span>
            </div>
            <div class="help-article-preview-title">
              {{ item.title }}
            </div>
            <div  class="help-article-preview-description">
              {{ truncate(plaintext(item.content), 200) }}
            </div>

          </div>

        </div>
        <HelpRequired></HelpRequired>


      </div>


    </div>
  </div>
</template>

<script>

import HelpTopics from './HelpTopics';
import HelpArticle from "./HelpArticle";
import Loader from './../Loader';
import HelpRequired from './HelpRequired';

export default {
  components: {
    HelpRequired,
    HelpTopics,
    HelpArticle,
    Loader
  },

  mounted: function () {
    this.load_topic();
  },
  watch: {
    '$route.query.help'() {
      this.load_topic();
    },
    'state.help.topic': function () {
      this.load_topic();
    }
  },
  methods: {
    truncate: function (text, length) {
      var suffix = '...';
      if (text.length > length) {
        var next_space = text.substring(length, text.length - length).indexOf(' ');
        var max = Math.max(next_space + length,length);

        return text.substring(0, max) + suffix;
      } else {
        return text;
      }
    },
    plaintext: function(content){
      return content.replace(/<[^>]+>/g, '');
    },
    validate: function () {
      if (this.searchquery == '') {
        this.searchresults = [];
      }
    },
    search: function () {
      this.loading_search = true;
      this.searchresults = [];
      if (this.searchquery != '') {
        axios.get('help/search/' + this.searchquery).then((response) => {
          this.searchresults = response.data;
          this.load_topic('index');
          this.loading_search = false;
        });
        this.$emit('help', 'index');
      }
    },
    load_topic: function () {
      if (this.$route.query.help != 'index') {
        this.loading = true;
      } else {
        this.loading = false;
      }
      this.article = null;
      this.state.help.topic = this.$route.query.help;
      axios.get('help/topics/' + this.$route.query.help).then((response) => {
        this.article = response.data;
        this.loading = false;
      }).finally(() => {

        this.loading = false;
      });
    }
  },
  computed: {

    envir: function () {
      return process.env.VUE_APP_CDNROOT;
    }
  },
  props: ['state'],
  data: function () {
    return {
      loading_search: false,
      searchquery: '',
      loading: true,
      article: null,
      searchresults: []
    }
  }

}
</script>
