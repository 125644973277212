<template>
    <div class="workarea-container globalsearch">
        <div class="row">

            <div class="col">
                <div class="card extended">

                    <div class="tabs">
                        <div class="tab-switcher">


                            <div class="tab-link" v-bind:class="{active : index == active_tab} "
                                 @click="active_tab = index" v-for="(item, index) in tabs">{{$t(item.title)}}
                                <template v-if="results[mappings[index]].length != 0">
                                    ({{results[mappings[index]].length}})
                                </template>
                            </div>


                            <div class="tab-mobile-switcher">
                                <div @click="previous_tab">
                                    <svgimg name="chevron-left"></svgimg>
                                </div>
                                <div @click="next_tab">
                                    <svgimg name="chevron-right"></svgimg>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div v-if="active_tab == index" v-for="(item, index) in mappings">
                        <h2 v-if="results[item].length == 0">{{$tc('results',{ count: results[item].length} )}}</h2>

                        <div class="result-item card" v-for="(itm,indx) in results[item]">
                            <a :href="'/' + item + '/' + itm.id">
                        <template v-if="item == 'users'">
                            <EmployeeAvatar :user="itm"></EmployeeAvatar>

                        </template>

                        <template v-else>
                            <div class="result-title" v-if="typeof itm != 'undefined'">
                                <template v-if="typeof itm.number != 'undefined'">{{ itm.number }} </template>
                                <template v-if="typeof itm.title != 'undefined'">{{ itm.title }} </template>
                                <template v-if="typeof itm.company != 'undefined'">{{ itm.company }} </template>
                                <template
                                    v-if="typeof itm.first_name != 'undefined' || typeof itm.last_name != 'undefined'">
                                    {{ itm.first_name }} {{ itm.last_name }}
                                </template>
                            </div>
                        </template>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>svg
<script>

import EmployeeSelector from "@/components/EmployeeSelector";
import EmployeeAvatar from "@/components/EmployeeAvatar";
import svgimg from "@/components/svgimg";
    export default {
        props: ['searchquery'],
        data: function(){
            return {
                results: {},
                filter: {
                    responsible_user_id_multiselect: [],
                    responsible_user_id: null
                },
                active_tab : '',
            }
        },
        watch: {
            searchquery : function(){
                this.load_search_results();
            }
        },
        computed: {
            tabs: function(){
                var tablist = [];
                for (const [key, value] of Object.entries(this.results)) {
                    tablist.push( { title: key, type: 'content' });
                }
                return tablist;
            },
            mappings: function(){
                var tablist = [];
                for (const [key, value] of Object.entries(this.results)) {
                    tablist.push( key);
                }
                return tablist;
            },
            reverse_mappings: function(){
                var tablist = {};
                var i = 0;
                for (const [key, value] of Object.entries(this.results)) {
                    tablist[key] = i;
                    i++;
                }
                return tablist;
            }
        },
        components: {
            EmployeeSelector,
            EmployeeAvatar,
            svgimg
        },
        methods : {previous_tab: function () {
                if (this.active_tab - 1 < 0) {
                    this.active_tab = this.tabs.length - 1;
                } else {
                    this.active_tab--;
                }
            },
            next_tab: function () {
                if (this.active_tab + 1 > this.tabs.length - 1) {
                    this.active_tab = 0;
                } else {
                    this.active_tab++;
                }
            },
            load_search_results: function(){

                axios.post('/search', {
                    'searchquery' : this.searchquery
                })
                    .then((response) => {
                        this.results = response.data;
                    });


            }
        }
    }
</script>
